import React from "react"

const overflowStyles = {
  overflow: "hidden",
  height: "auto",
}

const ScreenClip = ({ children }) => {
  return <div style={overflowStyles}>{children}</div>
}

ScreenClip.propTypes = {}

ScreenClip.defaultProps = {}

export default ScreenClip
