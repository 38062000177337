import React from "react"
import ContentWithImage from "../../../content-with-image/ContentWithImage"
import { animate } from "../../../../utilities/animate"
import { ButtonLink } from "../../../button"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"
import cn from "classnames"
import styles from "./Careers.module.scss"

const Careers = ({ file }) => {
  const breakpoint = useBreakpoint()

  return (
    <ContentWithImage
      background={"dark"}
      eyebrowText={"Careers"}
      image={{
        alt: "Martian team",
        fluid: file.childImageSharp.fluid,
      }}
      verticalAlign={"bottom"}
      horizontalAlign={"left"}
      imageShape={"square"}
    >
      <div>
        <h4 className={cn(styles.title, "serif")} {...animate()}>
          Join us and create a real impact with your work!
        </h4>
        <div {...animate()}>
          <ButtonLink
            to={"/careers/"}
            full={breakpoint === "xs"}
            variant={"primary"}
            dark={false}
          >
            Explore careers
          </ButtonLink>
        </div>
      </div>
    </ContentWithImage>
  )
}

Careers.propTypes = {}

Careers.defaultProps = {}

export default Careers
