import React from "react"
import PropTypes from "prop-types"
import styles from "./Mantra.module.scss"
import { animate } from "../../../../utilities/animate"
import { Holder } from "../../../holder"
import cn from "classnames"
import Wrapper from "../../../wrapper/Wrapper"
import ArticleBox from "./ArticleBox"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"
import { ArticleSlider } from "./ArticleSlider"
import { ButtonLink } from "../../../button"
import { removePreviewArticles } from "../../../../utilities/articlesHelper"

const Mantra = ({ articles }) => {
  const breakpoint = useBreakpoint()

  let filteredArticles = articles
  if (process.env.GATSBY_ENVIRONMENT === "production") {
    filteredArticles = removePreviewArticles(articles)
  }

  // limit to 3
  filteredArticles.edges = filteredArticles.edges.slice(0, 3)

  return (
    <div className={styles.wrapper}>
      <Wrapper>
        <Holder>
          <h6 className={styles.sectionName} {...animate()}>
            Mantra
          </h6>
          <h4 className={cn(styles.title, "serif")} {...animate()}>
            <span>What drives the digital business of tomorrow?</span>{" "}
            <span>Read stories from our team.</span>
          </h4>

          <div className={styles.bottomWrap}>
            <div className={styles.articlesWrap} {...animate()}>
              {breakpoint === "xs" || breakpoint === "sm" ? (
                <ArticleSlider articles={filteredArticles.edges} />
              ) : (
                <div className={styles.articles}>
                  {filteredArticles.edges.map(({ node: article }, i) => {
                    return (
                      <div className={styles.article} key={i}>
                        <ArticleBox
                          type={article.type}
                          slug={article.slug}
                          image={article.thumbnail || article.headerImage}
                          title={article.title}
                          excerpt={article.excerpt?.excerpt}
                        />
                      </div>
                    )
                  })}
                </div>
              )}
            </div>

            <div className={styles.buttonWrap} {...animate()}>
              <ButtonLink
                to={"/mantra/"}
                full={breakpoint === "xs"}
                variant={"primary"}
                dark={false}
                className={styles.button}
              >
                Visit our blog
              </ButtonLink>
            </div>
          </div>
        </Holder>
      </Wrapper>
    </div>
  )
}

Mantra.propTypes = {
  articles: PropTypes.object,
}

Mantra.defaultProps = {
  articles: undefined,
}

export default Mantra
