import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import styles from "./ArticleSlider.module.scss"

import ArticleBox from "./ArticleBox"
import React from "react"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"

export const ArticleSlider = ({ articles }) => {
  const breakpoint = useBreakpoint()
  const spaceBetween = breakpoint === "xs" ? 30 : 50

  return (
    <Swiper
      modules={[Pagination]}
      slidesPerView={1}
      pagination={{ clickable: true }}
      spaceBetween={spaceBetween}
      className={styles.slider}
      autoHeight={true}
    >
      {articles.map(({ node: article }, i) => {
        return (
          <SwiperSlide key={i}>
            <ArticleBox
              type={article.type}
              slug={article.slug}
              image={article.thumbnail || article.headerImage}
              title={article.title}
              excerpt={article.excerpt?.excerpt}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
