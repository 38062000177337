import React, { useState } from "react"
import PropTypes from "prop-types"
import styles from "./TrackRecord.module.scss"
import cn from "classnames"
import { animate } from "../../../../utilities/animate"

import logoAllianz from "./assets/logo-allianz.svg"
import logoViessmann from "./assets/logo-viessmann.svg"
import logoPorsche from "./assets/logo-porsche.svg"
import logoOpel from "./assets/logo-opel.svg"
import logoHpi from "./assets/logo-hpi.svg"
import logoFalke from "./assets/logo-falke.svg"
import logoDaimler from "./assets/logo-daimler.svg"
import logoVw from "./assets/logo-vw.svg"
import { Holder } from "../../../holder"

import logoCito from "./assets/logo-cito.svg"
import logoData4Life from "./assets/logo-data-4-life.svg"
import logoIgnitePrism from "./assets/logo-ignite-prism.svg"
import logoLikeminded from "./assets/logo-likeminded.svg"
import logoSalam from "./assets/logo-salam.svg"
import logoRing from "./assets/logo-ring.svg"
import logoTable from "./assets/logo-table.svg"
import logoUrbify from "./assets/logo-urbify.svg"

const Tab = {
  Corporates: "corporates",
  Startups: "startups",
}

const ListGrid = ({ children }) => {
  return (
    <div className={styles.listGrid}>
      <div className={styles.line} {...animate()} />
      <div className={styles.line} {...animate()} />
      <div className={styles.line} {...animate()} />

      <div className={styles.logoList}>{children}</div>
    </div>
  )
}

const Corporates = () => {
  return (
    <ListGrid>
      <div className={cn(styles.logoWrap, styles.logoPorsche)} {...animate()}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Porsche"} src={logoPorsche} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoHpi)} {...animate()}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Hpi"} src={logoHpi} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoAllianz)} {...animate()}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Allianz"} src={logoAllianz} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoVw)} {...animate()}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Vw"} src={logoVw} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoDaimler)} {...animate()}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Daimler"} src={logoDaimler} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoFalke)} {...animate()}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Falke"} src={logoFalke} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoOpel)} {...animate()}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Opel"} src={logoOpel} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoViessmann)} {...animate()}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Viessmann"} src={logoViessmann} />
        </div>
      </div>
    </ListGrid>
  )
}

const Startups = () => {
  return (
    <ListGrid>
      <div className={cn(styles.logoWrap, styles.logoCito)}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Cito"} src={logoCito} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoUrbify)}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Urbify"} src={logoUrbify} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoData4Life)}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo data4life"} src={logoData4Life} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoLikeminded)}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Likeminded"} src={logoLikeminded} />
        </div>
      </div>

      <div className={cn(styles.logoWrap, styles.logoRing)}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Ring"} src={logoRing} />
        </div>
      </div>

      <div className={cn(styles.logoWrap, styles.logoSalam)}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Salam"} src={logoSalam} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoIgnitePrism)}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Ignite Prism"} src={logoIgnitePrism} />
        </div>
      </div>
      <div className={cn(styles.logoWrap, styles.logoTable)}>
        <div className={styles.logoWrapInner}>
          <img alt={"logo Table"} src={logoTable} />
        </div>
      </div>
    </ListGrid>
  )
}

const TrackRecord = () => {
  const [activeTab, setActiveTab] = useState(Tab.Corporates)

  return (
    <div className={styles.wrapper}>
      <Holder className={styles.holder}>
        <h6 className={styles.sectionName} {...animate()}>
          Track record
        </h6>
        <h4 className={cn(styles.title, "serif")} {...animate()}>
          We have partnered and helped build more than 40 startups and worked
          with leading corporations on more than 100 innovation projects.
        </h4>

        <div className={styles.buttons} {...animate()}>
          <button
            className={cn(styles.button, styles.buttonCorporates, "h6", {
              [styles.buttonActive]: activeTab === Tab.Corporates,
            })}
            onClick={() => {
              setActiveTab(Tab.Corporates)
            }}
          >
            Corporates
          </button>
          <button
            className={cn(styles.button, styles.buttonStartups, "h6", {
              [styles.buttonActive]: activeTab === Tab.Startups,
            })}
            onClick={() => {
              setActiveTab(Tab.Startups)
            }}
          >
            Startups
          </button>
        </div>

        <div
          className={cn(styles.tab, {
            [styles.tabActive]: activeTab === Tab.Corporates,
          })}
        >
          <Corporates />
        </div>

        <div
          className={cn(styles.tab, {
            [styles.tabActive]: activeTab === Tab.Startups,
          })}
        >
          <Startups />
        </div>
      </Holder>
    </div>
  )
}

TrackRecord.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

TrackRecord.defaultProps = {
  className: "",
  children: undefined,
}

export default TrackRecord
