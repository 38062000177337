/* eslint-disable  jsx-a11y/img-redundant-alt */
import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./ContentWithImage.module.scss"
import { Section, SectionHeading } from "../section"
import Img from "gatsby-image"
import { animate } from "../../utilities/animate"

const ContentWithImage = ({
  className,
  sectionClassName,
  image,
  eyebrowText,
  background,
  horizontalAlign,
  verticalAlign,
  imageShape,
  children,
}) => {
  const blackBg = background === "black"

  return (
    <div
      className={cn(
        styles.container,
        {
          [styles.alignRight]: horizontalAlign === "right",
          [styles.alignBottom]: verticalAlign === "bottom",
          [styles.alignTop]: verticalAlign === "top",
          [styles.blackBackground]: blackBg,
        },
        className
      )}
    >
      <Section
        background={background}
        noBottomSpacing={true}
        noTopSpacing={true}
        className={sectionClassName}
      >
        <div className={cn(styles.wrapper)}>
          <div className={styles.imageWrapper}>
            <div
              className={cn(styles.imageInner, {
                [styles.squareImage]: imageShape === "square",
                [styles.wideImage]: imageShape === "wide",
              })}
            >
              <div className={styles.expansionWrapper} {...animate()}>
                <Img
                  className={styles.image}
                  fluid={image?.fluid}
                  alt={image?.alt}
                  style={{ position: "absolute" }}
                  fadeIn={true}
                />
              </div>
            </div>
          </div>

          <div className={styles.content}>
            <div className={styles.contentInner}>
              <SectionHeading marginBottom={"sm"} order={""} animated={true}>
                {eyebrowText}
              </SectionHeading>
              {children}
            </div>
          </div>
        </div>
      </Section>
    </div>
  )
}

ContentWithImage.propTypes = {
  className: PropTypes.string,
  sectionClassName: PropTypes.string,
  image: PropTypes.shape({
    alt: PropTypes.string,
    fluid: PropTypes.object,
  }),
  eyebrowText: PropTypes.string,
  background: PropTypes.oneOf(["light", "dark", "black"]),
  children: PropTypes.object,
  horizontalAlign: PropTypes.oneOf(["left", "right"]),
  verticalAlign: PropTypes.oneOf(["top", "bottom"]),
  imageShape: PropTypes.oneOf(["square", "tall", "wide"]),
}

ContentWithImage.defaultProps = {
  className: "",
  sectionClassName: "",
  image: undefined,
  eyebrowText: "",
  background: undefined,
  children: undefined,
  horizontalAlign: "left",
  verticalAlign: "top",
  imageShape: "tall",
}

export default ContentWithImage
