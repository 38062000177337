import PropTypes from "prop-types"

export const ImageType = PropTypes.shape({
  fixed: PropTypes.shape({
    height: PropTypes.number,
    src: PropTypes.string,
    width: PropTypes.number,
  }),
  title: PropTypes.string,
})
