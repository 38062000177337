import PropTypes from "prop-types"

export const articleTypeResearch = "Research"
export const articleTypeThoughts = "Thoughts"
export const articleTypeInsights = "Insights"
export const articleTypeInterviews = "Interviews"

export const ArticleType = PropTypes.oneOf([
  articleTypeInsights,
  articleTypeResearch,
  articleTypeThoughts,
  articleTypeInterviews,
])
