import React, { Component } from "react"
import { Layout } from "components/layout"
import { Meta } from "components/meta"
import { Hero } from "components/hero"
import Mission from "../components/sections/index/mission/Mission"
import ScreenClip from "../components/screen-clip/ScreenClip"
import { graphql } from "gatsby"
import Approach from "../components/sections/index/approach/Approach"
import Portfolio from "../components/sections/index/portfolio/Portfolio"
import TrackRecord from "../components/sections/index/track-record/TrackRecord"
import OurFocus from "../components/sections/index/our-focus/OurFocus"
import Careers from "../components/sections/index/careers/Careers"
import Mantra from "../components/sections/index/mantra/Mantra"

class Index extends Component {
  render() {
    const { location, data } = this.props

    const heroProps = {
      isIndex: true,
      title: "Building startups from scratch",
      subtitle:
        "Martian & Machine is a Venture Studio focused on building digital products and turning them into successful companies.",
      showBackgroundElements: true,
    }

    return (
      <ScreenClip>
        <Layout location={location}>
          <Meta
            title={"Venture Studio"}
            description={
              "Martian & Machine is a Venture Studio focused on building digital products and turning them into successful companies."
            }
          />
          <Hero {...heroProps} />
          <Mission file={data.missionImage} />
          <Approach />
          <Portfolio file={data.portfolioImage} />
          <TrackRecord />
          <OurFocus />
          <Careers file={data.careersImage} />
          <Mantra articles={data.articles} />
        </Layout>
      </ScreenClip>
    )
  }
}

export const query = graphql`
  query {
    missionImage: file(relativePath: { eq: "index/mission.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    portfolioImage: file(relativePath: { eq: "index/portfolio.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    careersImage: file(relativePath: { eq: "index/careers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 860, webpQuality: 95) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    articles: allContentfulArticle(
      filter: { featured: { eq: true } }
      limit: 5
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          preview
          createdAt
          id
          type
          date(formatString: "DD.MM.YYYY")
          title
          slug
          headerImage {
            title
            fixed(width: 600, quality: 75) {
              aspectRatio
              base64
              height
              src
              srcSet
              srcWebp
              width
              srcSetWebp
            }
          }
          thumbnail {
            title
            fixed(width: 600, quality: 75) {
              aspectRatio
              base64
              height
              src
              srcSet
              srcWebp
              width
              srcSetWebp
            }
          }
          excerpt {
            excerpt
          }
        }
      }
    }
  }
`

export default Index
