import React from "react"
import ContentWithImage from "../../../content-with-image/ContentWithImage"
import Planets from "./Planets"
import { animate } from "../../../../utilities/animate"
import { ButtonLink } from "../../../button"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"
import cn from "classnames"
import styles from "./Mission.module.scss"

const Mission = ({ file }) => {
  const breakpoint = useBreakpoint()

  return (
    <div className={styles.wrapper}>
      <div className={styles.planets}>
        <Planets />
      </div>

      <ContentWithImage
        background={"dark"}
        eyebrowText={"Mission"}
        image={{
          alt: "Martian team",
          fluid: file.childImageSharp.fluid,
        }}
        className={styles.section}
        horizontalAlign={"left"}
        verticalAlign={"bottom"}
        imageShape={"square"}
      >
        <div>
          <h4 className={cn(styles.title, "serif")} {...animate()}>
            We enable entrepreneurs and intrapreneurs to build the next
            generation of great tech products and&nbsp;companies.
          </h4>
          <div {...animate()}>
            <ButtonLink
              to={"/about-us/"}
              full={breakpoint === "xs"}
              variant={"primary"}
              dark={false}
            >
              Discover more
            </ButtonLink>
          </div>
        </div>
      </ContentWithImage>
    </div>
  )
}

Mission.propTypes = {}

Mission.defaultProps = {}

export default Mission
