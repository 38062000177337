import React from "react"
import PropTypes from "prop-types"
import styles from "./OurFocus.module.scss"
import graphicCheckmark from "../../../../assets/images/common/graphic-checkmark.svg"
import graphicRectangle from "../../../../assets/images/common/graphic-rectangle.svg"
import { animate } from "../../../../utilities/animate"
import { Holder } from "../../../holder"
import cn from "classnames"
import Wrapper from "../../../wrapper/Wrapper"

const OurFocus = () => {
  return (
    <div className={styles.wrapper}>
      <Wrapper>
        <Holder>
          <h6 className={styles.sectionName} {...animate()}>
            Our focus
          </h6>
          <h4 className={cn(styles.title, "serif")} {...animate()}>
            We believe in great founders and want to enable them to create
            successful startups, wherever they come from
          </h4>

          <div className={styles.columns}>
            <div className={styles.columnSeparator} {...animate()} />
            <div className={styles.leftCol}>
              <div className={styles.colInner}>
                <img
                  className={styles.image}
                  src={graphicCheckmark}
                  alt="OurFocus graphic"
                  {...animate()}
                />
                <h3 className={styles.colTitle} {...animate()}>
                  Startups
                </h3>
                <p className={"h5"} {...animate()}>
                  Founders who want to build startups faster and more
                  efficiently
                </p>
              </div>
            </div>
            <div className={styles.rightCol}>
              <div className={styles.colInner}>
                <img
                  className={styles.image}
                  src={graphicRectangle}
                  alt="OurFocus graphic"
                  {...animate()}
                />
                <h3 className={styles.colTitle} {...animate()}>
                  Corporate startups
                </h3>
                <p className={"h5"} {...animate()}>
                  Leading corporates that want to innovate through startup
                  creation
                </p>
              </div>
            </div>
          </div>
        </Holder>
      </Wrapper>
    </div>
  )
}

OurFocus.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

OurFocus.defaultProps = {
  className: "",
  children: undefined,
}

export default OurFocus
