import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./Wrapper.module.scss"

const Wrapper = React.forwardRef(({ className, children }, ref) => {
  return (
    <div ref={ref} className={cn(className, styles.wrapper)}>
      {children}
    </div>
  )
})

Wrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Wrapper.defaultProps = {
  className: "",
  children: undefined,
}

export default Wrapper
