import React from "react"
import ContentWithImage from "../../../content-with-image/ContentWithImage"
import { animate } from "../../../../utilities/animate"
import { ButtonLink } from "../../../button"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"
import cn from "classnames"
import styles from "./Portfolio.module.scss"

const Portfolio = ({ file }) => {
  const breakpoint = useBreakpoint()

  return (
    <ContentWithImage
      background={"dark"}
      eyebrowText={"Portfolio"}
      image={{
        alt: "Portfolio image",
        fluid: file.childImageSharp.fluid,
      }}
      verticalAlign={"bottom"}
      horizontalAlign={"right"}
      imageShape={"wide"}
    >
      <div>
        <h4 className={cn(styles.title, "serif")} {...animate()}>
          Our ventures and products have raised €100M+ in funding globally.
        </h4>
        <div {...animate()}>
          <ButtonLink
            to={"/portfolio/"}
            full={breakpoint === "xs"}
            variant={"primary"}
            dark={false}
          >
            Explore portfolio
          </ButtonLink>
        </div>
      </div>
    </ContentWithImage>
  )
}

Portfolio.propTypes = {}

Portfolio.defaultProps = {}

export default Portfolio
