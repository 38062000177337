import React from "react"
import PropTypes from "prop-types"
import styles from "./ArticleBox.module.scss"
import { Link } from "../../../link"
import Img from "gatsby-image"
import { ImageType } from "../../../../types/ImageType"
import {
  articleTypeInsights,
  articleTypeInterviews,
  articleTypeResearch,
} from "../../../../types/ArticleType"
import { useBreakpoint } from "../../../../hooks/useBreakpoint"
import imgResearch from "../../../mantra/meta/assets/research.svg"
import imgInsights from "../../../mantra/meta/assets/insights.svg"
import imgInterviews from "../../../mantra/meta/assets/interviews.svg"

const style = {
  position: "absolute",
}

const ArticleBox = ({ image, slug, type, title, excerpt, body, ...rest }) => {
  const link = `/mantra/${type.toLowerCase()}/${slug}/`
  const breakpoint = useBreakpoint()
  const TitleTag = breakpoint === "xs" ? "h3" : "h4"

  const isResearch = type === "Research"
  const isInsights = type === "Insights"
  const isInterview = type === "Interviews"

  return (
    <div className={styles.box} {...rest}>
      <Link to={link}>
        <div>
          <div className={styles.image}>
            <Img
              fixed={image.fixed}
              key={image.fixed.src}
              alt={image.title}
              style={style}
              fadeIn={true}
              className={"article-box-main-image"}
            />
          </div>
          <div className={styles.type}>
            {isResearch && (
              <img
                className={styles.typeImage}
                src={imgResearch}
                alt="Research"
              />
            )}
            {isInsights && (
              <img
                className={styles.typeImage}
                src={imgInsights}
                alt="Insights"
              />
            )}
            {isInterview && (
              <img
                className={styles.typeImage}
                src={imgInterviews}
                alt="Interviews"
              />
            )}
            <h6 className={styles.typeName}>{type}</h6>
          </div>
          <TitleTag className={styles.title}>{title}</TitleTag>
          <p className={styles.excerpt}>{excerpt}</p>
        </div>
      </Link>
    </div>
  )
}

ArticleBox.propTypes = {
  image: ImageType.isRequired,
  slug: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    articleTypeInsights,
    articleTypeResearch,
    articleTypeInterviews,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
}

ArticleBox.defaultProps = {
  excerpt: "",
}

export default ArticleBox
