import React from "react"
import cn from "classnames"
import styles from "./Planets.module.scss"
import Plx from "react-plx"

const background = [
  {
    start: "20vh",
    end: "100vh",
    easing: "ease",
    properties: [
      {
        startValue: 0,
        endValue: -30,
        unit: "px",
        property: "translateY",
      },
    ],
  },
]

const marsData = [
  {
    start: "0vh",
    end: "60vh",
    easing: "ease",
    properties: [
      {
        startValue: 1.1,
        endValue: 1.1,
        property: "scale",
      },
    ],
  },
  {
    start: "30vh",
    end: "150vh",
    easing: "ease",
    properties: [
      {
        startValue: 1,
        endValue: 0.5,
        property: "opacity",
      },
    ],
  },
  {
    start: "60vh",
    end: "120vh",
    easing: "ease",
    properties: [
      {
        startValue: 0,
        endValue: 30,
        unit: "px",
        property: "translateY",
      },
      {
        startValue: 1.1,
        endValue: 1,
        property: "scale",
      },
    ],
  },
]

const Planets = () => {
  return (
    <div className={cn(styles.wrapper)}>
      <div className={styles.circle} />

      <Plx disabled={false} parallaxData={marsData} className={styles.marsPlx}>
        <div className={styles.mars} />
      </Plx>

      <Plx
        disabled={false}
        parallaxData={background}
        className={styles.backgroundPlx}
      >
        <div className={styles.background} />
      </Plx>
    </div>
  )
}

Planets.propTypes = {}

Planets.defaultProps = {}

export default Planets
