import React from "react"
import PropTypes from "prop-types"
import styles from "./Approach.module.scss"
import approachGraphic from "./assets/approach-graphic.svg"
import { animate } from "../../../../utilities/animate"

const Approach = () => {
  return (
    <div className={styles.wrapper}>
      <h6 className={styles.sectionName} {...animate()}>
        Approach
      </h6>
      <img
        className={styles.image}
        src={approachGraphic}
        alt="Approach graphic"
        {...animate()}
      />
      <h2 className={styles.title} {...animate()}>
        Our approach is simple. We identify problems - we build solutions.
      </h2>
    </div>
  )
}

Approach.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

Approach.defaultProps = {
  className: "",
  children: undefined,
}

export default Approach
